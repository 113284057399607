import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import slugify from "slugify"

import ProductDetails from "../components/ProductDetails"
import Seo from "../components/Seo"

const ProductTemplate = ({ data, pageContext }) => {
  const slugifiedNavItem = slugify(pageContext.navItem, {
    lower: true,
    locale: "de",
  })
  const slugifiedProductType = slugify(pageContext.productType, {
    lower: true,
    locale: "de",
  })

  return (
    <>
      <Seo
        title={`${data?.productData?.title} - ${process.env.GATSBY_SHOP_OWNER} Onlineshop`}
        description={`${process.env.GATSBY_SHOP_OWNER} Onlineshop: ${data?.productData?.title} online bestellen.`}
      />
      <BreadcrumbWrapper>
        <Breadcrumb to="/shop">Shop</Breadcrumb>
        <span className="breadcrumb-divider">|</span>
        <Breadcrumb to={`/shop/${slugifiedNavItem}`}>
          {pageContext.navItem}
        </Breadcrumb>
        <span className="breadcrumb-divider">|</span>
        <Breadcrumb to={`/shop/${slugifiedNavItem}/${slugifiedProductType}`}>
          {pageContext.productType}
        </Breadcrumb>
        <span className="breadcrumb-divider">|</span>
        <Location>{data.productData.title}</Location>
      </BreadcrumbWrapper>
      <ProductDetails data={data} />
    </>
  )
}

export const query = graphql`
  query ProductDetailsQuery(
    $handle: String!
    $vendor: String!
    $navItem: String!
  ) {
    productData: shopifyProduct(handle: { eq: $handle }) {
      id
      title
      descriptionHtml
      handle
      storefrontId
      vendor
      productType
      tags
      featuredImage {
        gatsbyImageData(aspectRatio: 1)
      }
      priceRangeV2 {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      variants {
        availableForSale
        inventoryQuantity
        storefrontId
        title
        price
        selectedOptions {
          name
          value
        }
      }
      media {
        ... on ShopifyMediaImage {
          id
          image {
            gatsbyImageData(aspectRatio: 1)
            altText
          }
        }
      }
      options {
        shopifyId
        name
        values
      }
      metafields {
        key
        value
      }
    }
    sameProducts: allShopifyProduct(
      filter: {
        vendor: { eq: $vendor }
        tags: { glob: $navItem }
        handle: { ne: $handle }
      }
    ) {
      nodes {
        id
        title
        handle
        productType
        vendor
        tags
        featuredImage {
          gatsbyImageData(aspectRatio: 1, height: 200, width: 200)
        }
        media {
          ... on ShopifyMediaImage {
            id
            image {
              gatsbyImageData(aspectRatio: 1)
              altText
            }
          }
        }
        priceRangeV2 {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        metafields {
          key
          value
        }
      }
    }
  }
`

const BreadcrumbWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.textLight};
  font-size: 0.9rem;
  margin: 2rem 2rem 0;
  white-space: nowrap;
  overflow-x: auto;
  padding: 0.5rem 0;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }

  a {
    /* margin-right: 0.5rem; */
    color: ${({ theme }) => theme.colors.textLight};

    :hover {
      /* text-decoration: underline; */
    }
  }

  span {
    margin-right: 0.5rem;
  }

  .breadcrumb-divider {
    display: inline-block;
    margin: 0 0.5rem;
  }

  @media (max-width: 500px) {
    margin-bottom: 1rem;
  }
`

const Breadcrumb = styled(Link)`
  display: inline-flex;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
`

const Location = styled.span`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.text};
`

export default ProductTemplate
